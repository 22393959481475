import {Http} from '@wix/wixstores-client-core/dist/es/src/http/http';

export const VolatileCartApiUrl = '/stores/v1/carts/volatileCart';

export class VolatileCartService {
  private readonly httpClient: Http;

  constructor(httpClient: Http) {
    this.httpClient = httpClient;
  }

  public getStandaloneCartId = async (
    productId: string,
    optionSelectionId: number[],
    quantity: number,
    customTextFieldSelection?: {title: string; value: string}[]
  ): Promise<string> => {
    const response = await this.httpClient.post(VolatileCartApiUrl, {
      productId,
      optionSelectionId,
      customTextFieldSelection,
      quantity,
    });
    return response.data.cart.id;
  };
}
